
import http from "./http";
import FPHTTP from './constants/fp';
// import setupProxy from "../setupProxy";

export const sendMessage = (body) => {
  return http.post("api/sendMessage", body).then((res) => res);
};

export const joinUs = (body) => {
  return http.post("api/v1/users/form/driver", body).then((res) => res);
};

//this api user profile returns
export const getMe = () => {
  return http.get('api/v1/users/profile').then(res => res.data);
};

export const login = (body) => {
  return http.post('api/v1/users/login', body).then(res => res.data);
};

export const getOrdersList = (offset, limit, orderStatus) => {
  return http.get(`api/v1/orders?limit=${limit}&offset=${offset}&orderStatus=${orderStatus}&role=USER`).then(res => res.data);
};
export const getGiftOrdersList = (offset, limit, orderStatus, id) => {
  return http.get(`api/v1/giftOrder?limit=${limit}&_id=${id}&offset=${offset}&orderStatus=${orderStatus}&role=USER`).then(res => res.data);
};
//IN_PROGRESS for active order

export const getKitchensList = () => {
  return http.get(`/api/v1/kitchens`).then(res => res.data);
};

export const getAllDishes = (id) => {
  return http.get(`api/v1/dishes/?role=CHEF&_id=${id}`).then(res => res.data);
};

export const deleteUser = (id) => {
  return http.delete(`api/v1/users/${id}`).then(res => res.data);
};

export const deleteOrder = (id) => {
  return http.delete(`api/v1/orders/${id}`).then(res => res.data);
};

export const deleteDish = (id) => {
  return http.delete(`api/v1/dishes/${id}`).then(res => res.data);
};

export const deleteKitchen = (id) => {
  return http.delete(`api/v1/kitchens/${id}`).then(res => res.data);
};

export const getProfitDetails = () => {
  return http.get(`/api/v1/financials`).then(res => res.data);
};

export const getDietary = () => {
  return http.get(`/api/v1/dietaries`).then(res => res.data);
};

export const getCategory = () => {
  return http.get(`/api/v1/categories`).then(res => res.data);
};

export const getCuisine = () => {
  return http.get(`/api/v1/cuisines`).then(res => res.data);
};

export const uploadImage = async (file) => {
  const response = await http({
    method: "post",
    url: "/api/s3",
    data: file,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const uploadImageWithoutSize = async (file) => {
  const response = await http({
    method: "post",
    url: "/api/withoutsize/s3",
    data: file,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const addDish = (body) => {
  return http.post(`/api/v1/dishes`, body).then(res => res.data);
};

export const updateUser = (id, body) => {
  return http.put(`api/v1/users/${id}`, body).then(res => res.data);
};
//id and body which one is changed

export const updateOrder = (id, body) => {
  return http.put(`api/v1/orders/${id}`, body).then(res => res.data);
};

export const getSingleOrder = (id) => {
  return http.get(`api/v1/orders/${id}`).then(res => res.data);
};

export const connectStripe = (body) => {
  return http.post(`/api/v1/dishes`, body).then(res => res.data);
};

export const createCoupon = (body) => {
  return http.post(`/api/v1/payments/stripe/coupon`, body).then(res => res.data);
};

export const getCoupon = (code) => {
  return http.get(`/api/v1/payments/stripe/coupon/${code}`).then(res => res.data);
};

export const deleteCoupon = (code) => {
  return http.delete(`/api/v1/payments/stripe/coupon/delete/${code}`).then(res => res.data);
};

export const updateDish = (id, body) => {
  return http.put(`api/v1/dishes/${id}`, body).then(res => res.data);
};

export const getDishById = (id, body) => {
  return http.get(`api/v1/dishes/${id}`, body).then(res => res.data);
};

export const signup = (body) => {
  return http.post('api/v1/users/signup', body).then(res => res.data);
};

export const forgotPassword = (body) => {
  return http.post(`api/v1/users/forgot-password`, body).then(res => res.data);
};

export const changePassword = (body) => {
  return http.post(`api/v1/users/change-password`, body).then(res => res.data);
};

export const updatePassword = (body) => {
  return http.post(`api/v1/users/update-password`, body).then(res => res.data);
};
//have to send oldPassword,password 

export const loginWithGoogle = (body) => {
  return http.post(`api/v1/users/loginsocial/google`, body).then(res => res.data);
};

export const getPopular = (id) => {
  return http.get(`api/v1/orders/popular/byChef/${id}`).then(res => res.data);
};

export const autoComplete = (input, token) => {
  return http.get(`api/v1/google/autocomplete/${input}/${token}`).then(res => res.data);
};

export const placeDetail = (id, token) => {
  return http.get(`api/v1/google/placedetails/${id}/${token}`).then(res => res.data);
};

export const verifyEmail = (body) => {
  return http.post(`api/v1/users/verify-email`, body).then(res => res.data);
};

export const verificationEmail = (body) => {
  return http.post(`api/v1/users/verification-email`, body).then(res => res.data);
};

export const getLast24HoursOrders = (id) => {
  return http.get(`api/v1/orders/last24hours/${id}`).then(res => res.data);
};

export const nearByResturants = (role, lat, long,offset, limit, requestRole, _id ) => {
  return http.get(`api/v1/users?role=${role}&lat=${lat}&lng=${long}&requestRole=${requestRole}&offset=${offset}&limit=${limit}`).then(res => res.data);
};
export const giftandFlowers = () => {
  return http.get(`api/v1/gifts`).then(res => res.data);
};
export const getgiftByid = (id) => {
  return http.get(`api/v1/gifts/${id}`).then(res => res.data);
};
export const topRestaurants = (lat, long ) => {
  return http.get(`api/v1/users/top-restaurants/${lat}/${long}`).then(res => res.data);
};

export const featureDeals = (lat, long ) => {
  return http.get(`api/v1/dishes/deals/offers?lat=${lat}$lng=${long}`).then(res => res.data);
};

export const autoCompleteSearch = (body) => {
  return http.post(`api/v1/search/autocomplete`, body).then(res => res.data);
};

export const keyworkSearch = (body) => {
  return http.post(`api/v1/search`, body).then(res => res.data);
};

export const sendVerificationEmail = (body) => {
  return http.post(`api/v1/users/send-verification-email`, body).then(res => res.data);
};

export const createCustomer = (body) => {
  return http.post(`api/v1/payments/customer/create`, body).then(res => res.data);
};

export const updateCustomer = (body) => {
  return http.post(`api/v1/payments/customer/update`, body).then(res => res.data);
};

export const getCustomer = (customerId) => {
  return http.get(`api/v1/payments/customer/${customerId}`).then(res => res.data);
};

export const createPaymentMethod = (body) => {
  return http.post(`api/v1/payments/payment-method/create`, body).then(res => res.data);
};

export const updatePaymentMethod = (pmId, body) => {
  return http.post(`api/v1/payments/payment-method/update/${pmId}`, body).then(res => res.data);
};

export const getpaymentMethod = (pmId) => {
  return http.get(`api/v1/payments/payment-method/${pmId}`).then(res => res.data);
};

export const attachPaymentMethodToCustomer = (body) => {
  return http.post(`api/v1/payments/payment-method/attach`, body).then(res => res.data);
};

export const createOrder = (body) => {
  return http.post(`api/v1/orders/`, body).then(res => res.data);
};
export const createGiftOrder = (body) => {
  return http.post(`api/v1/giftorder/`, body).then(res => res.data);
};

export const createCheckout = (body) => {
  return http.post(`api/v1/payments/checkout`, body).then(res => res.data);
};
export const detachPaymentMethod = (pmId) => {
  return http.post(`api/v1/payments/payment-method/detach/${pmId}`).then(res => res.data);
};

export const getCouponDetails = (Id) => {
  return http.get(`api/v1/payments/coupon/${Id}`).then(res => res.data);
};


//////////////////////////////////////////////////////////////////////////////
//ForunPoint Api's

export const createTrip = (body) => {
  return FPHTTP.post(`/trips/create`, body).then(res => res.data);
};
// export const jazzcash = (body) => {
//   return setupProxy.post(`/api/CustomerPortal/transactionmanagement/merchantform/`, body).then(res => res);
// };