import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import FilterSection from "../home/filters/filter";
// import deal1 from '../../assets/images/deal1.png'
// import deal2 from '../../assets/images/deal2.png'
// import deal3 from '../../assets/images/deal3.png'
import {
  nearByResturants,
  topRestaurants,
  featureDeals,
  autoCompleteSearch,
  keyworkSearch,
  giftandFlowers,
  getMe,
} from "../../shared/apis";
// import { FaHeart } from "react-icons/fa";
// import { FaRegHeart } from "react-icons/fa";
import { IoIosStar, IoIosAdd } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import "./restaurants.css";
import { settings, settings2, settings3 } from "./settings";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import GiftDetailsPopup from "../dialogs/gift-detail-popup/gift-popup";
import CartComponent from "../home/cart/cart";
import Toast from "react-bootstrap/Toast";
import { PiForkKnifeFill } from "react-icons/pi";
import { HiGift } from "react-icons/hi2";
import { ConvertPKRtoUSD } from '../../App';
import { Helmet } from 'react-helmet';

const Carousel = () => {
  const sliderRef = useRef(null);
  const [resturants, setresturants] = useState([]);
  const [topResturants, setTopResturants] = useState([]);
  const [gifts, setGifts] = useState([]);
  // const [topGifts, setTopGifts] = useState([]);
  const [featuredDeals, setfeaturedDeals] = useState([]);
  const [likedRestaurants, setLikedRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTop, setIsLoadingTop] = useState(false);
  const [isLoadingDeals, setIsLoadingDeals] = useState(false);
  // const [coordinates] = useState([33.148150, -96.763130]);
  // const [coordinates, setCoordinates] = useState([31.3728262, 74.1592163]);
  const [userLoc, setUserLoc] = useState({});
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();
  const set = settings;
  const set2 = settings2;
  const set3 = settings3;
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchType, setSearchType] = useState("DISH");
  const divRef = useRef(null);
  const [searchResult, setSearchResults] = useState([]);
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const [orderType, setOrderType] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGift, setSelectedGift] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [giftItems, setGiftItems] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeTab, setActiveTab] = useState("food");
  // const [activeTab1, setActiveTab1] = useState('dish');
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const [button, setButton] = useState("dish");
  const [dishActive, setDishActive] = useState(true);
  const storedCountry = localStorage.getItem('country');

  

  // const [limitedTimeOffers, setLimitedTimeOffers] = useState([
  //   { id: 1, image: deal1 },
  //   { id: 2, image: deal2, },
  //   { id: 3, image: deal3, },
  //   { id: 5, image: deal2, },
  //   { id: 6, image: deal1, },
  //   { id: 7, image: deal2, },
  //   { id: 8, image: deal3, },
  //   { id: 9, image: deal2, },
  // ]);

  const handleTabSwitch1 = (tab) => {
    setActiveTab(tab);
    giftfoodSwitcher("food");
  };

  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.value = inputValue; // Reset the value to trigger the cursor to the end
      inputRef.current.setSelectionRange(inputValue.length, inputValue.length);
    }
  };

  const handleTabSwitch2 = (tab) => {
    setActiveTab(tab);
    giftfoodSwitcher("gift");
  };
  const handleTabSwitch3 = async (tab) => {
    setIsLoading(true);
    // setActiveTab1(tab);
    dishRestSwitcher("dish");
    let body = {};
    let results = [];
    setSearchType("DISH");
    body = {
      dish: searchedKeyword,
      lat: userLoc.lat,
      lng: userLoc.lng,
      limit: 20,
    };
    results = await keyworkSearch(body);
    window.scrollTo(0, 0);
    setSearchResults(results.data.dishes);
    setIsSearchFocused(false);
    setIsSearchTriggered(true);
    setIsLoading(false);
  };

  const handleTabSwitch4 = async (tab) => {
    setIsLoading(true);
    // setActiveTab1(tab);
    dishRestSwitcher("restaurant");
    let body = {};
    let results = [];
    setSearchType("RESTAURANT");
    body = {
      chef: searchedKeyword,
      lat: userLoc.lat,
      lng: userLoc.lng,
      limit: 20,
    };
    results = await keyworkSearch(body);
    window.scrollTo(0, 0);
    setSearchResults(results.data.users);
    setIsSearchFocused(false);
    setIsSearchTriggered(true);
    setIsLoading(false);
  };

  const toastStyle = {
    position: "fixed",
    top: `${scrollPosition}px`,
    right: "10px",
    zIndex: 1000,
  };

  // const handleRestaurantClick = () => {
  //     setSearchType('RESTAURANT');
  // };

  // const handleSearchFocus = () => {
  //     setIsSearchFocused(true);
  // };

  const handleDishClick = () => {
    setDishActive(true);
    document.getElementById("dish-btn").style.background = "var(--Gradient-Primary, linear-gradient(180deg, #D4110F 0%, #FF4D00 100%))";
    document.getElementById("dish-btn").style.color = "#FFF";
    document.getElementById("restaurant-btn").style.background = "#FFF";
    document.getElementById("restaurant-btn").style.color = "#d4110f";
    setButton("dish");
    setSearchType("DISH");
  };

  const handleRestaurantClick = () => {
    setDishActive(false);
    document.getElementById("dish-btn").style.background = "#FFF";
    document.getElementById("dish-btn").style.color = "#d4110f";
    document.getElementById("restaurant-btn").style.background = "var(--Gradient-Primary, linear-gradient(180deg, #D4110F 0%, #FF4D00 100%))";
    document.getElementById("restaurant-btn").style.color = "#FFF";
    setButton("restaurant");
    setSearchType("RESTAURANT");
  };

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
    // setSearchType('DISH'); // Set the default search type to 'RESTAURANT'
  };

  const init = async () => {
    scrollToTop();
    setIsLoading(true);
    setIsLoadingTop(true);
    setIsLoadingDeals(true);
    let me = await getMe();
    const userAddress = JSON.parse(localStorage.getItem("UserAddress"));
    // const userCountry = localStorage.getItem("country");
    // console.log(userCountry)

    let latlng = {};
    if (me?.data?.user?.location?.coordinates) {
      latlng.lat = me.data.user.location.coordinates[1];
      latlng.lng = me.data.user.location.coordinates[0];
    } else if (userAddress) {
      latlng.lat = userAddress.location.coordinates[1];
      latlng.lng = userAddress.location.coordinates[0];
    }
    setUserLoc(latlng);
    let topResturant = await topRestaurants(latlng.lat, latlng.lng);
    setTopResturants(
      topResturant.data.restaurants.filter((user) => user.coverPhoto !== "")
    );
    setIsLoadingTop(false);

    let user = await nearByResturants(
      "CHEF",
      latlng.lat,
      latlng.lng,
      offset,
      30
    );
    setresturants(user.data.users.filter((user) => user.coverPhoto !== ""));
    setIsLoading(false);

    let featuresDeals = await featureDeals(latlng.lat, latlng.lng);
    setfeaturedDeals([
      ...featuresDeals.data.coupons,
      ...featuresDeals.data.offers,
      ...featuresDeals.data.deals,
    ]);
    setIsLoadingDeals(false);

    let gifts = await giftandFlowers();
    let usdgifts = [];
    const storedCountry = localStorage.getItem('country');
    // console.log(storedCountry)
    if (storedCountry === 'US') {
      gifts.data.Gifts.map(gift => {
        gift.price = gift.priceUSD;
        usdgifts.push(gift);
      });
    } else {
      usdgifts = gifts.data.Gifts;
    }

    setGifts(usdgifts);
  };

  useEffect(() => {
    init();
    const handleScroll = (event) => {
      if (sliderRef.current && sliderRef.current.contains(event.target)) {
        sliderRef.current.scrollTop += event.deltaY;
      }
    };
    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
    const handleResize = () => {
      if (sliderRef.current) {
        const containerWidth = sliderRef.current.clientWidth;
        const newSlidesToShow = Math.floor(containerWidth / 300);
        setSlidesToShow(newSlidesToShow);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // eslint-disable-next-line
  }, []);

  const haversine = (lat1, lon1, lat2, lon2) => {
    const toRadians = (angle) => angle * (Math.PI / 180);

    lat1 = toRadians(lat1);
    lon1 = toRadians(lon1);
    lat2 = toRadians(lat2);
    lon2 = toRadians(lon2);

    let dlat = lat2 - lat1;
    let dlon = lon2 - lon1;

    let a =
      Math.sin(dlat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const R = 6371;
    let distance = R * c;
    return distance.toFixed(2);
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const handleHeartClick = (userId) => {
    const updatedLikedRestaurants = likedRestaurants.includes(userId)
      ? likedRestaurants.filter((id) => id !== userId)
      : [...likedRestaurants, userId];
    setLikedRestaurants(updatedLikedRestaurants);
  };

  const detectMaxScroll = async () => {
    const scrollableDiv = sliderRef.current;
    const currentScroll = scrollableDiv.scrollTop;
    const maxScroll = scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
    if (currentScroll === maxScroll) {
      setOffset(offset + 30);
      let user = await nearByResturants(
        "CHEF",
        userLoc.lat,
        userLoc.lng,
        offset,
        30
      );
      let usersWithCover = user.data.users.filter(
        (user) => user.coverPhoto !== ""
      );
      setresturants((resturants) => [...resturants, ...usersWithCover]);
    }
  };

  const goToRestaurantHome = (id, dishId) => {
    if (searchType === "DISH") {
      navigate(`/restaurant-home/${id}?dish=${dishId}`);
    } else {
      navigate(`/restaurant-home/${id}`);
    }
  };

  const searchAutoComplete = async (input) => {
    let body = {
      dish: input,
      type: searchType,
      lat: userLoc.lat,
      lng: userLoc.lng,
    };
    if (input.length > 0) {
      setIsSearchFocused(true);
      let suggestions = await autoCompleteSearch(body);
      setSuggestions(suggestions.keywords);
    } else {
      setIsSearchFocused(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOutsideClick = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsSearchFocused(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      handleOutsideClick(event);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const searchKeyword = async (keyword) => {
    setSearchedKeyword(keyword);
    let body = {};
    let results = [];

    if (searchType === "DISH") {
      body = {
        dish: keyword,
        lat: userLoc.lat,
        lng: userLoc.lng,
      };
    } else {
      body = {
        chef: keyword,
        lat: userLoc.lat,
        lng: userLoc.lng,
      };
    }
    results = await keyworkSearch(body);
    if (searchType === "DISH") {
      setSearchResults(results.data.dishes);
    } else {
      setSearchResults(results.data.users);
    }

    setIsSearchFocused(false);
    setIsSearchTriggered(true);
  };

  const filterSearch = async (keyword) => {
    setSearchedKeyword(keyword);
    let body = {};
    let results = [];
    setSearchType("DISH");
    if (searchType === "DISH") {
      body = {
        dish: keyword,
        lat: userLoc.lat,
        lng: userLoc.lng,
        limit: 20,
      };
    } else {
      body = {
        chef: keyword,
        lat: userLoc.lat,
        lng: userLoc.lng,
      };
    }
    results = await keyworkSearch(body);
    window.scrollTo(0, 0);
    if (searchType === "DISH") {
      setSearchResults(results.data.dishes);
    } else {
      setSearchResults(results.data.users);
    }
    setIsSearchFocused(false);
    setIsSearchTriggered(true);
  };

  //   const checkUserLocation = (() => {

  // });

  const goBack = () => {
    setSearchResults([]);
    init();
    setIsSearchTriggered(false);
    setIsSearchFocused(false);
  };
  const giftfoodSwitcher = (type) => {
    if (type === "food") {
      setOrderType(false);
    } else if (type === "gift") {
      setOrderType(true);
    }
  };
  const dishRestSwitcher = (type) => {
    if (type === "dish") {
      setSearchType("DISH");
    } else if (type === "restaurant") {
      setSearchType("RESTAURANT");
    }
  };
  const closeModal = () => {
    setSelectedGift(null);
    setIsModalOpen(false);
  };
  const addToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      setCartItems((prevCartItems) =>
        prevCartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems((prevCartItems) => [...prevCartItems, item]);
    }
    setTimeout(() => {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, 600);
  };
  const giftToCart = (item) => {
    let currentGiftItems = JSON.parse(localStorage.getItem('giftItems')) || [];

    console.log("Current gift items from localStorage:", currentGiftItems);
    console.log("New gift item to add:", item);

    // Update quantity if item already exists in the gift cart
    let updatedGiftItems = currentGiftItems.map((giftItem) => {
      if (giftItem.id === item.id) {
        console.log(`Increasing quantity for gift item with ID: ${giftItem.id}`);
        return { ...giftItem, quantity: giftItem.quantity + 1 };
      }
      return giftItem;
    });

    console.log("Gift items after quantity update:", updatedGiftItems);

    // Add new item if it doesn't exist in the gift cart
    if (!updatedGiftItems.some(giftItem => giftItem.id === item.id)) {
      console.log("Gift item not found in cart, adding new gift item:", item);
      updatedGiftItems = [...updatedGiftItems, { ...item, quantity: 1 }];
    }

    console.log("Gift items after adding new item if necessary:", updatedGiftItems);

    // Filter out gift items with quantity < 1
    updatedGiftItems = updatedGiftItems.filter(giftItem => giftItem.quantity > 0);

    console.log("Gift items after filtering out items with quantity < 1:", updatedGiftItems);

    // Update localStorage with the new gift items
    localStorage.setItem('giftItems', JSON.stringify(updatedGiftItems));
    console.log("Updated giftItems in localStorage:", JSON.parse(localStorage.getItem('giftItems')));

    // Update state with the new gift items
    setGiftItems(updatedGiftItems);
  };

  const showToastFunction = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };
  const openModal = (gift) => {
    setSelectedGift(gift);
    setIsModalOpen(true);
  };

  const handleImageLoaded = () => {
    setImageLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>Discover Top Restaurants Nearby & Best Deals | Find Restaurants</title>
      </Helmet>
      <Navbar />

      {showToast && (
        <Toast style={toastStyle}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>Sign In to continue.</Toast.Body>
        </Toast>
      )}

      <div className="restaurants-page">
        {!orderType && (
          <div class="rest-search">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-8">
                <div class="rest-search-bar">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Restaurant, Foods, etc"
                    onFocus={handleSearchFocus}
                    onChange={(e) => {
                      searchAutoComplete(e.target.value);
                    }}
                  />
                  <a>
                    <button class="toggle-btn btn-primary">Search Food</button>
                  </a>
                </div>
                {isSearchFocused && (
                  <div ref={divRef} className="search-section">
                    {/* <div className="toggle-btns">
                                        <button
                                            className={`btn-primary nav-item restaurant-btn`}
                                            id='restaurant-btn'
                                            onMouseDown={handleRestaurantClick}
                                        >
                                            Restaurants
                                        </button>
                                    </div> */}
                    <div className="toggle-btns">
                      <button
                        id="dish-btn"
                        className={`dish-btn ${dishActive ? "dish-active" : ""
                          }`}
                        onMouseDown={handleDishClick}
                      >
                        Dishes
                      </button>
                      <button
                        className={`restaurant-btn ${!dishActive ? "restaurant-active" : ""
                          }`}
                        id="restaurant-btn"
                        onMouseDown={handleRestaurantClick}
                      >
                        Restaurants
                      </button>
                    </div>
                    {/* <div className="toggle-btns">
                                        <button
                                            id='dish-btn'
                                            className={`btn-primary nav-item dish-btn ${button === 'dish' ? 'dish-active' : ''}`}
                                            onMouseDown={handleDishClick}
                                            style={{ backgroundColor: button === 'dish' ? '#d4110f' : '', color: button === 'dish' ? 'white' : '' }}
                                        >
                                            Dishes
                                        </button>
                                        <button
                                            className={`btn-primary nav-item restaurant-btn ${button === 'restaurant' ? 'restaurant-active' : ''}`}
                                            id='restaurant-btn'
                                            onMouseDown={handleRestaurantClick}
                                            style={{ backgroundColor: button === 'restaurant' ? '#d4110f' : '', color: button === 'restaurant' ? 'white' : '' }}

                                        >
                                            Restaurants
                                        </button>
                                    </div> */}
                    {suggestions.map((keyword) => (
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => searchKeyword(keyword)}
                      >
                        {keyword}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!orderType && isSearchTriggered && (
          <div className="rest-results-content">
            <div className="dish-rest-btns">
              <button
                className={
                  searchType === "DISH" ? "food-btn active" : "food-btn"
                }
                onClick={() => handleTabSwitch3("dish")}
              >
                Dishes
              </button>
              <button
                className={
                  searchType === "RESTAURANT" ? "food-btn active" : "food-btn"
                }
                onClick={() => handleTabSwitch4("restaurant")}
              >
                Restaurants
              </button>
            </div>
            {searchType === "RESTAURANT" && (
              <h3 className="rest-results-title">
                <a href="/restaurants">
                  <FaArrowLeft
                    onClick={() => goBack()}
                    className="left-arrow"
                  />
                </a>{" "}
                {searchResult?.length} Restaurants Found
              </h3>
            )}
            {searchType === "DISH" && (
              <h3 className="rest-results-title">
                <a href="/restaurants">
                  <FaArrowLeft
                    onClick={() => goBack()}
                    className="left-arrow"
                  />
                </a>{" "}
                {searchResult?.length} Dishes Found
              </h3>
            )}
            <hr />
            <div
              className="vertical-slider"
              ref={sliderRef}
              slidesToShow={slidesToShow}
              onScroll={detectMaxScroll}
            >
              {isLoading && (
                <div className="slider-content d-flex justify-content-center align-items-center">
                  <div className="mt-5" style={{ textAlign: "center" }}>
                    <Spinner animation="border" variant="danger" />
                  </div>
                </div>
              )}
              {!isLoading && searchType === "RESTAURANT" && (
                <div className="slider-content">
                  {searchResult.map((user) => (
                    <div
                      onClick={() => goToRestaurantHome(user._id)}
                      className="restaurant-card-2"
                    >
                      <div className="rest-card">
                        <img
                          src={user?.coverPhoto}
                          alt=""
                          className="restaurant-img"
                        />
                      </div>
                      <div className="rest-card-body">
                        <p className="rest-title rest-title3">
                          {user?.restaurantName?.substring(0, 25)}
                        </p>
                        <div className="bottom-row">
                          <p className="mile-sec2">
                            {haversine(
                              userLoc.lat,
                              userLoc.lng,
                              user?.location?.coordinates[1],
                              user?.location?.coordinates[0]
                            )}{" "}
                            km
                          </p>
                          <div className="rating-section2 star-sec">
                            <p className="rating-amounts2">
                              ({user?.rating}) <IoIosStar className="star" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!isLoading && searchType === "DISH" && (
                <div className="slider-content">
                  {searchResult.map((dish) => (
                    <div
                      onClick={() =>
                        goToRestaurantHome(dish?.user?._id, dish?._id)
                      }
                      className="results-card"
                    >
                      <div className="image-container">
                        <img
                          src={dish.picture}
                          className="result-card-img"
                          alt="..."
                        />
                      </div>
                      <div className="results-card-body">
                        <div className="results-card-details">
                          <p className="rest-title rest-title2">
                            {dish?.dishName?.substring(0, 25)}
                          </p>
                          <p className="results-prices">
                            {/* {haversine(
                              userLoc.lat,
                              userLoc.lng,
                              dish?.location?.coordinates[1],
                              dish?.location?.coordinates[0]
                            )}{" "}
                            km */}
                            {/* {dish?.price ? (
                              <span>
                                {dish.price !== dish.fullPrice ? (
                                  <>
                                    <p className='price-food2'>
                                      {ConvertPKRtoUSD(dish.price)}
                                    </p>
                                  </>
                                ) : (
                                  <p className='price-food2'>
                                    {ConvertPKRtoUSD(dish.price)}
                                  </p>
                                )}
                              </span>
                            ) : (
                              <span>
                                {dish?.sizes?.[0]?.price !== dish?.sizes?.[0]?.fullPrice ? (
                                  <>
                                    <p className='price-food2'>
                                      {ConvertPKRtoUSD(dish?.sizes?.[0]?.price)}
                                    </p>
                                  </>
                                ) : (
                                  <p className='price-food2'>
                                    {ConvertPKRtoUSD(dish?.sizes?.[0]?.price)}
                                  </p>
                                )}
                              </span>
                            )} */}
                            {dish?.price ? (
                              <span>
                                {storedCountry === 'US' ? (
                                  <p className='price-food2'>
                                    {dish.priceUSD ? `$${dish.priceUSD}` : ConvertPKRtoUSD(dish.price)}
                                  </p>
                                ) : (
                                  <p className='price-food2'>
                                    Rs {dish.price}
                                  </p>
                                )}
                              </span>
                            ) : (
                              <span>
                                {storedCountry === 'US' ? (
                                  <p className='price-food2'>
                                    {dish.sizes[0].priceUSD ? `$${dish.sizes[0].priceUSD}` : ConvertPKRtoUSD(dish.sizes[0].price)}
                                  </p>
                                ) : (
                                  <p className='price-food2'>
                                    Rs {dish.sizes[0].price}
                                  </p>
                                )}
                              </span>
                            )}


                          </p>
                        </div>
                        <div className="results-rating-sec ">
                          <p className="rating-amount">
                            ({dish?.user?.rating}){" "}
                            <IoIosStar className="results-star" />
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {!isSearchTriggered && (
          <div className="switch-btns">
            <button
              className={activeTab === "food" ? "food-btn active" : "food-btn"}
              onClick={() => handleTabSwitch1("food")}
            >
              <PiForkKnifeFill className="forks-ico" /> Foods & Flavors
            </button>
            <button
              className={
                activeTab === "gift" ? "gifts-btn active" : "gifts-btn"
              }
              onClick={() => handleTabSwitch2("gift")}
            >
              <HiGift className="gift-ico" /> Flowers & Gifts
            </button>
          </div>
        )}

        {!orderType && !isSearchTriggered && (
          <div className="carousel-container">
            <Slider {...set}>
              {[...Array(22).keys()].map((index) => (
                <div key={index} className="carousel-card">
                  {index === 0 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("burger")}
                            src="./images/burger.png"
                            alt=""
                            className="img-styling"
                          />
                          <p
                            onClick={() => filterSearch("burger")}
                            className="food-name"
                          >
                            Burger
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 1 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("pizza")}
                            src="./images/pizza.png"
                            alt=""
                            className="img-styling"
                          />
                          <p
                            onClick={() => filterSearch("pizza")}
                            className="food-name"
                          >
                            Pizza
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 2 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("bbq")}
                            src="./images/BBQ.png"
                            alt=""
                            className="img-styling-2"
                          />
                          <p
                            onClick={() => filterSearch("bbq")}
                            className="food-name"
                          >
                            BBQ
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 3 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("biryani")}
                            src="./images/biryani.png"
                            alt=""
                            className="img-styling-3"
                          />
                          <p
                            onClick={() => filterSearch("biryani")}
                            className="food-name"
                          >
                            Biryani
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 4 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("fries")}
                            src="./images/fries.png"
                            alt=""
                            className="img-styling-4"
                          />
                          <p
                            onClick={() => filterSearch("fries")}
                            className="food-name"
                          >
                            Fries
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 5 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("broast")}
                            src="./images/broast.png"
                            alt=""
                            className="img-styling-5"
                          />
                          <p
                            onClick={() => filterSearch("broast")}
                            className="food-name"
                          >
                            Broast
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* {index === 6 ? (
                                        <>
                                            <div className="cat-content">
                                                <img onClick={() => filterSearch('korean')} src="./images/korean.png" alt="" className="food-img" />
                                                <p onClick={() => filterSearch('korean')} className='food-name'>Korean</p>
                                            </div>
                                        </>
                                    ) : null} */}
                  {/* {index === 6 ? (
                                        <>
                                            <div className="cat-content">
                                                <img onClick={() => filterSearch('indian')} src="./images/indian.png" alt="" className="food-img" />
                                                <p onClick={() => filterSearch('indian')} className='food-name'>Indian</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {index === 8 ? (
                                        <>
                                            <div className="cat-content">
                                                <img onClick={() => filterSearch('halal')} src="./images/halal.png" alt="" className="food-img" />
                                                <p onClick={() => filterSearch('halal')} className='food-name'>Halal</p>
                                            </div>
                                        </>
                                    ) : null} */}
                  {index === 6 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("karahi")}
                            src="./images/karahi.png"
                            alt=""
                            className="img-styling-6"
                          />
                          <p
                            onClick={() => filterSearch("karahi")}
                            className="food-name"
                          >
                            Karahi
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 7 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("pasta")}
                            src="./images/pasta.png"
                            alt=""
                            className="img-styling-7"
                          />
                          <p
                            onClick={() => filterSearch("pasta")}
                            className="food-name"
                          >
                            Pasta
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 8 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("breakfast")}
                            src="./images/breakfast.png"
                            alt=""
                            className="img-styling-8"
                          />
                          <p
                            onClick={() => filterSearch("breakfast")}
                            className="food-name"
                          >
                            Breakfast
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 9 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("samosa")}
                            src="./images/samosa.png"
                            alt=""
                            className="img-styling-9"
                          />
                          <p
                            onClick={() => filterSearch("samosa")}
                            className="food-name"
                          >
                            Samosa
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 10 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("desert, dessert")}
                            src="./images/deserts.png"
                            alt=""
                            className="img-styling-10"
                          />
                          <p
                            onClick={() => filterSearch("desert, dessert")}
                            className="food-name"
                          >
                            Desserts
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 11 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("shakes")}
                            src="./images/shake.png"
                            alt=""
                            className="img-styling-11"
                          />
                          <p
                            onClick={() => filterSearch("shakes")}
                            className="food-name"
                          >
                            Shakes
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 12 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("ice cream")}
                            src="./images/icecream.png"
                            alt=""
                            className="img-styling-12"
                          />
                          <p
                            onClick={() => filterSearch("ice cream")}
                            className="food-name"
                          >
                            Ice Cream
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 13 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("donut")}
                            src="./images/donut.png"
                            alt=""
                            className="img-styling"
                          />
                          <p
                            onClick={() => filterSearch("donut")}
                            className="food-name"
                          >
                            Donut
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 14 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("coffee")}
                            src="./images/coffee.png"
                            alt=""
                            className="img-styling"
                          />
                          <p
                            onClick={() => filterSearch("coffee")}
                            className="food-name"
                          >
                            Coffee
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 15 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("veggie")}
                            src="./images/veggie.png"
                            alt=""
                            className="img-styling-15"
                          />
                          <p
                            onClick={() => filterSearch("veggie")}
                            className="food-name"
                          >
                            Veggie
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 16 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("pulao")}
                            src="./images/pulao.png"
                            alt=""
                            className="img-styling-16"
                          />
                          <p
                            onClick={() => filterSearch("pulao")}
                            className="food-name"
                          >
                            Pulao
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 17 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("steak")}
                            src="./images/steak.png"
                            alt=""
                            className="img-styling-17"
                          />
                          <p
                            onClick={() => filterSearch("steak")}
                            className="food-name"
                          >
                            Steak
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 18 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("wraps")}
                            src="./images/wraps.png"
                            alt=""
                            className="img-styling-18"
                          />
                          <p
                            onClick={() => filterSearch("wraps")}
                            className="food-name"
                          >
                            Wraps
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 19 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("sandwich")}
                            src="./images/sandwich.png"
                            alt=""
                            className="img-styling-19"
                          />
                          <p
                            onClick={() => filterSearch("sandwich")}
                            className="food-name"
                          >
                            Sandwich
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 20 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("rolls")}
                            src="./images/rolls.png"
                            alt=""
                            className="img-styling-20"
                          />
                          <p
                            onClick={() => filterSearch("rolls")}
                            className="food-name"
                          >
                            Rolls
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {index === 21 ? (
                    <>
                      <div className="cat-content">
                        <div className="img-div">
                          <img
                            onClick={() => filterSearch("drink")}
                            src="./images/beverage.png"
                            alt=""
                            className="img-styling-21"
                          />
                          <p
                            onClick={() => filterSearch("drink")}
                            className="food-name"
                          >
                            Beverages
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              ))}
            </Slider>
            <h2 className="deal-section-title">Limited time <span className="offer-txt">Offer</span></h2>
            <div className="deal-section row">
              <div className="featur-dealS">
                {isLoadingDeals && (
                  <div className="mt-2" style={{ textAlign: "center" }}>
                    <Spinner animation="border" variant="danger" />
                  </div>
                )}
                {!isLoadingDeals && (
                  <Slider className="limited-time-sec" {...set3}>
                    {featuredDeals.map((dish) => (
                      <div key={dish._id}>
                        <div
                          onClick={() =>
                            goToRestaurantHome(dish?.user?._id, dish?._id)
                          }
                          className="offer-card"
                        >
                          <img
                            src={dish?.picture}
                            alt=""
                            className="offer-image"
                          />
                        </div>
                      </div>
                    ))}
                    {/* {limitedTimeOffers.map((offer) => (
                      <div className="offer-card" key={offer.id}>
                        <img src={offer.image} alt={offer.name} className="offer-image" />
                      </div>
                    ))} */}
                  </Slider>
                )}
              </div>
            </div>


            {/* <div className="deal-section row">
              <div className="deal-details">
                <div className="card-body featured-body">
                  <div className="body-wrapper">
                    <h5 className="card-title">LIMITED</h5>
                    <h5 className="card-title">TIME</h5>
                    <p className="card-text">OFFER</p>
                  </div>
                </div>
              </div>
              <div className="featur-dealS">
                {isLoading ? (
                  <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
                    {[...Array(4)].map((_, index) => (
                      <div key={index} className="deal-card" style={{ marginRight: "10px" }}>
                        <Skeleton width={250} height={100} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <Slider {...set3} className="slick-slider">
                    {featuredDeals.map((dish) => (
                      <div key={dish._id}>
                        <div
                          onClick={() => goToRestaurantHome(dish?.user?._id, dish?._id)}
                          className="deal-card"
                        >
                          <img
                            src={dish?.picture}
                            alt=""
                            className="feature-img"
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div> */}
            <FilterSection filterSearch={filterSearch} />
            <div className="top-restaurants">
              <h3 className="restaurant-heading">Top Restaurants</h3>
              {isLoadingTop && (
                <div className="mt-5" style={{ textAlign: "center" }}>
                  {/* <Skeleton width={200} height={100} />  */}
                  <Spinner animation="border" variant="danger" />

                </div>
              )}
              {!isLoadingTop && (
                <Slider {...set2} className="top-restaurant-slider">
                  {topResturants.map((user) => (
                    <div
                      onClick={() => goToRestaurantHome(user._id)}
                      key={user._id}
                      className="restaurant-card"
                    >
                      <div className="rest-card">
                        <img
                          src={user?.coverPhoto}
                          alt="Restaurant Cover Photo"
                          className="restaurant-img"
                        />
                      </div>
                      <div className="rest-card-body">
                        <p className="rest-title">
                          {user?.restaurantName?.substring(0, 25)}
                        </p>
                        <div className="bottom-row">
                          <p className="mile-sec2">
                            {haversine(
                              userLoc.lat,
                              userLoc.lng,
                              user?.location?.coordinates[1],
                              user?.location?.coordinates[0]
                            )}{" "}
                            km
                          </p>
                          <div className="rating-section2 star-sec">
                            <p className="rating-amounts2">
                              ({user?.rating}) <IoIosStar className="star" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              <h3 className="restaurant-heading">Nearby Restaurants</h3>
              <div
                className="vertical-slider"
                ref={sliderRef}
                slidesToShow={slidesToShow}
                onScroll={detectMaxScroll}
              >
                {isLoading && (
                  <div className="slider-content d-flex justify-content-center align-items-center">
                    <div className="mt-5" style={{ textAlign: "center" }}>
                      {/* <Skeleton width={200} height={100} /> */}
                      <Spinner animation="border" variant="danger" />

                    </div>
                  </div>
                )}
                {!isLoading && (
                  <div className="slider-content">
                    {resturants.map((user) => (
                      // <div onClick={() => goToRestaurantHome(user._id)} key={user._id} className="nearby-card">
                      //     <div className="heart-icon" onClick={() => handleHeartClick(user._id)}>
                      //         {likedRestaurants.includes(user._id) ? <FaHeart /> : <FaRegHeart />}
                      //     </div>
                      //     <div className="image-container">
                      //         <img src={user?.coverPhoto} className="nearby-img" alt="..." />
                      //     </div>
                      //     <div className="nearby-card-body">
                      //         <div className='nearby-content'>
                      //             <p className='rest-title'>{user?.restaurantName?.substring(0, 10)}</p>
                      //         </div>
                      //         <div className="bottom-row">
                      //     <p className='mile-sec2'>{haversine(coordinates[0], coordinates[1], user?.location?.coordinates[1], user?.location?.coordinates[0])} km</p>
                      //     <div className="rating-section2 star-sec">
                      //         <p className="rating-amounts2">({user?.rating}) <IoIosStar className='star' /></p>
                      //     </div>
                      //     </div>
                      //     </div>
                      // </div>
                      <div
                        onClick={() => goToRestaurantHome(user._id)}
                        key={user._id}
                        className="restaurant-card-2"
                      >
                        <div className="rest-card">
                          <img
                            src={user?.coverPhoto}
                            alt=""
                            className="restaurant-img restaurantimg3"
                          />
                        </div>
                        <div className="rest-card-body rest-cardbody2">
                          <p className="rest-title">
                            {user?.restaurantName?.substring(0, 25)}
                          </p>
                          <div className="bottom-row">
                            <p className="mile-sec2">
                              {haversine(
                                userLoc.lat,
                                userLoc.lng,
                                user?.location?.coordinates[1],
                                user?.location?.coordinates[0]
                              )}{" "}
                              km
                            </p>
                            <div className="rating-section2 star-sec">
                              <p className="rating-amounts2">
                                ({user?.rating}) <FaStar className="star" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div></div>
          </div>
        )}
      </div >

      {orderType && (
        <div className="restaurants-page">
          <div class="rest-search">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-8">
                {/* <div class="rest-search-bar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Restaurant, Foods, etc"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    ref={inputRef}
                  />
                  <a>
                    <button class="toggle-btn btn-primary">Search</button>
                  </a>
                </div> */}
                {isSearchFocused && (
                  <div ref={divRef} className="search-section">
                    {/* <div className="toggle-btns">
                                        <button
                                            className={`btn-primary nav-item restaurant-btn`}
                                            id='restaurant-btn'
                                            onMouseDown={handleRestaurantClick}
                                        >
                                            Restaurants
                                        </button>
                                    </div> */}
                    {suggestions.map((keyword) => (
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => searchKeyword(keyword)}
                      >
                        {keyword}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="switch-btns">
            <button
              className={activeTab === "food" ? "food-btn active" : "food-btn"}
              onClick={() => handleTabSwitch1("food")}
            >
              <PiForkKnifeFill className="forks-ico" /> Food & Flavors
            </button>
            <button
              className={
                activeTab === "gift" ? "gifts-btn active" : "gifts-btn"
              }
              onClick={() => handleTabSwitch2("gift")}
            >
              <HiGift className="gift-ico" /> Flowers & Gifts
            </button>
          </div>
          {isSearchTriggered && (
            <div className="rest-results-content">
              <h3 className="rest-results-title">
                <a href="/restaurants">
                  <FaArrowLeft
                    onClick={() => goBack()}
                    className="left-arrow"
                  />
                </a>{" "}
                {searchResult?.length} Restaurants Found
              </h3>
              <hr />
              <div
                className="vertical-slider"
                ref={sliderRef}
                slidesToShow={slidesToShow}
                onScroll={detectMaxScroll}
              >
                {isLoading && (
                  <div className="slider-content d-flex justify-content-center align-items-center">
                    <div className="mt-5" style={{ textAlign: "center" }}>
                      {/* <Skeleton width={200} height={100} /> */}
                      <Spinner animation="border" variant="danger" />
                    </div>
                  </div>
                )}
                {!isLoading && (
                  <div className="slider-content">
                    {searchResult.map((user) => (
                      <div
                        onClick={() => goToRestaurantHome(user._id)}
                        className="results-card"
                      >
                        <div className="image-container">
                          <img
                            src={user.coverPhoto}
                            className="result-card-img"
                            alt="..."
                          />
                        </div>
                        <div className="results-card-body">
                          <div className="results-card-details">
                            <p className="rest-title">
                              {user?.restaurantName?.substring(0, 9)}
                            </p>
                            <p className="results-mile">
                              {haversine(
                                userLoc.lat,
                                userLoc.lng,
                                user?.location?.coordinates[1],
                                user?.location?.coordinates[0]
                              )}{" "}
                              km
                            </p>
                          </div>
                          <div className="results-rating-sec ">
                            <p className="rating-amount">
                              ({user.rating}){" "}
                              <IoIosStar className="results-star" />
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          {!isSearchTriggered && (
            <div className="carousel-container">
              {/* <hr /> */}
              {/* <div className="deal-section row">
                                <div className="deal-details">
                                    <div className="card-body featured-body">
                                        <div className="body-wrapper">
                                            <h5 className="card-title">Featured</h5>
                                            <p className="card-text">Deals</p>
                                        </div>
                                        <div className='deal-time'>00:00</div>
                                    </div>
                                </div>
                                <div className='featur-dealS'>
                                    {isLoading &&
                                        <div className='mt-5' style={{ textAlign: 'center' }}>
                                            <Spinner animation="border" variant="danger" />
                                        </div>
                                    }
                                    {!isLoading &&
                                        <Slider {...set3}>
                                            {featuredDeals.map((dish) => (
                                                <div key={dish._id}>
                                                    <div className="deal-card">
                                                        <img src={dish?.picture} alt="" className="feature-img" />
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    }
                                </div>
                            </div> */}
              <hr />
              {/* <FilterGift filterSearch={filterSearch} /> */}
              <div className="gifts-section">
                <div className="top-restaurants gifts-flowers">
                  <h3 className="restaurant-heading">Nearby Gifts & Flowers</h3>
                  <hr />
                  <div
                    className="vertical-slider2"
                    ref={sliderRef}
                    slidesToShow={slidesToShow}
                    onScroll={detectMaxScroll}
                  >
                    {isLoading && (
                      <div className="slider-content d-flex justify-content-center align-items-center">
                        <div className="mt-5" style={{ textAlign: "center" }}>
                          {/* <Skeleton width={200} height={100} /> */}
                          <Spinner animation="border" variant="danger" />


                        </div>
                      </div>
                    )}
                    {!isLoading && (
                      <div className="slider-content">
                        {gifts
                          .filter((gift) => gift.type === "FLOWERS")
                          .slice(0, 25)
                          .map((gift) => (
                            <div
                              className="nearby-card gift-card"
                              onClick={() => openModal(gift)}
                            >
                              <div className="plus-sign">
                                <IoIosAdd className="plus-ico" />
                              </div>

                              <div className="image-container">
                                <img
                                  src={gift?.picture}
                                  className="nearby-img gift-image"
                                  alt="..."
                                />
                              </div>
                              <div className="nearby-card-body gift-card-body">
                                <div className="gifts-content row-direction">
                                  <p className="rest-title rest-title4">
                                    {gift?.name?.substring(0, 15)}
                                  </p>
                                  <p className="gifts-price">{ConvertPKRtoUSD(gift.price)}</p>
                                  {/* <p className='mile'>{haversine(33.148150, -96.763130, ?.location?.coordinates[1], user?.location?.coordinates[0])} km</p> */}
                                </div>
                                {/* <div className="rating-section star-section">
                                                            <p className="rating-amount">({gift?.rating}) <IoIosStar className='star' /></p>
                                                        </div> */}
                              </div>
                            </div>
                          ))}
                        {gifts
                          .filter((gift) => gift.type === "SWEETS")
                          .slice(0, 25)
                          .map((gift) => (
                            <div
                              className="nearby-card gift-card"
                              onClick={() => openModal(gift)}
                            >
                              <div className="plus-sign">
                                <IoIosAdd className="plus-ico" />
                              </div>

                              <div className="image-container">
                                <img
                                  src={gift?.picture}
                                  className="nearby-img gift-image"
                                  alt="..."
                                />
                              </div>
                              <div className="nearby-card-body gift-card-body">
                                <div className="gifts-content">
                                  <p className="rest-title rest-title4">
                                    {gift?.name?.substring(0, 15)}
                                  </p>
                                  <p className="gifts-price">{ConvertPKRtoUSD(gift.price)}</p>
                                  {/* <p className='mile'>{haversine(33.148150, -96.763130, ?.location?.coordinates[1], user?.location?.coordinates[0])} km</p> */}
                                </div>
                                {/* <div className="rating-section star-section">
                                                            <p className="rating-amount">({gift?.rating}) <IoIosStar className='star' /></p>
                                                        </div> */}
                                {/* {gift?.description?.length > 0 && <div className="category-description-card-body">
                                  {gift?.description?.substring(0, 30) + '...'}
                                </div>
                                } */}
                              </div>
                            </div>
                          ))}
                        {gifts
                          .filter((gift) => gift.type === "PARTY_ITEM")
                          .slice(0, 25)
                          .map((gift) => (
                            <div
                              className="nearby-card gift-card"
                              onClick={() => openModal(gift)}
                            >
                              <div className="plus-sign">
                                <IoIosAdd className="plus-ico" />
                              </div>

                              <div className="image-container">
                                <img
                                  src={gift?.picture}
                                  className="nearby-img gift-image"
                                  alt="..."
                                />
                              </div>
                              <div className="nearby-card-body gift-card-body">
                                <div className="gifts-content">
                                  <p className="rest-title">
                                    {gift?.name?.substring(0, 15)}
                                  </p>
                                  <p className="gifts-price">{ConvertPKRtoUSD(gift.price)}</p>
                                  {/* <p className='mile'>{haversine(33.148150, -96.763130, ?.location?.coordinates[1], user?.location?.coordinates[0])} km</p> */}
                                </div>
                                {/* <div className="rating-section star-section">
                                                            <p className="rating-amount">({gift?.rating}) <IoIosStar className='star' /></p>
                                                        </div> */}
                              </div>
                            </div>
                          ))}

                        {gifts
                          .filter((gift) => gift.type === "")
                          .slice(0, 25)
                          .map((gift) => (
                            <div
                              className="nearby-card gift-card"
                              onClick={() => openModal(gift)}
                            >
                              <div className="plus-sign">
                                <IoIosAdd className="plus-ico" />
                              </div>

                              <div className="image-container">
                                <img
                                  src={gift?.picture}
                                  className="nearby-img gift-image"
                                  alt="..."
                                />
                              </div>
                              <div className="nearby-card-body gift-card-body">
                                <div className="gifts-content">
                                  <p className="rest-title">
                                    {gift?.name?.substring(0, 15)}
                                  </p>
                                  <p className="gifts-price">{ConvertPKRtoUSD(gift.price)}</p>
                                  {/* <p className='mile'>{haversine(33.148150, -96.763130, ?.location?.coordinates[1], user?.location?.coordinates[0])} km</p> */}
                                </div>
                                {/* <div className="rating-section star-section">
                                                            <p className="rating-amount">({gift?.rating}) <IoIosStar className='star' /></p>
                                                        </div> */}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <GiftDetailsPopup
                      isOpen={isModalOpen}
                      onClose={closeModal}
                      giftDetails={selectedGift}
                      giftToCart={giftToCart}
                    />
                  </div>
                </div>
                <div className="cart-section">
                  <CartComponent
                    showToast={showToastFunction}
                    itemsInCart={cartItems}
                    giftInCart={giftItems}
                    addToCart={addToCart}
                    giftToCart={giftToCart}
                  />
                </div>
              </div>
              <div></div>
            </div>
          )}
        </div>
      )
      }

      <Footer />
    </>
  );
};

export default Carousel;
