import Navbar from "../../Navbar/Navbar";
import CookieConsent from "../../cookies-consent/cookies-consent";
import "./LandingHome.css";
import Autocomplete from '../../home/Autocomplete/Autocomplete';
import salad from '../../../assets/images/salad.png'
import landingImg from '../../../assets/images/landing-img.png'
import forunpointText from '../../../assets/images/forunpoint-text.png'
import rider from '../../../assets/images/rider.png'

const LandingHome = () => {
  return (
    <div className="landing-home-container">
      <Navbar />
      <div className="landing-page">
        <div className="image-sec"></div>
        <div className="landing-content">
          <img src={forunpointText} alt="Forunpoint Text" className="forunpoint-text" />
          <h1 className="landing-heading">Food and Gift Delivery!</h1>
          <p className="landing-description">Chutki Bajate Forun Hazir!</p>
        </div>
        <img src={salad} className="salad-img" alt="Salad" />
        <img src={landingImg} className="dish-img" alt="Dish" />
        <img src={rider} className="rider-img" alt="Rider" />
        <div className="row landingcard2">
          <div className="col-md-6 col-lg-5">
            <div className="landing-card">
              <div className="card-body">
                <div className="input-group md-form form-sm form-2 pl-0">
                  <Autocomplete />
                  <div className="input-group-append"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent />
    </div>
  );
};

export default LandingHome;
